import { Code } from "../content/typography";
import Table from "../components/Table";
import { API_SERVER } from "../settings";

let media_url = API_SERVER + "static/media/"

function Output({ code, output, metadata }) {
  var output_data = null;
  if (output[0]) {
    if (output[0].data) {
      if ("text/html" in output[0].data) {
        output_data = <Table>{output[0].data["text/html"].join("")}</Table>;
      } else {
        output_data = (
          <Code language="" code={output[0].data["text/plain"].join("")} />
        );
      }
    }
  }

  if (metadata.tags) {
    if (metadata.tags[0] === "hide-source") {
      return <div className="output">{output_data}</div>;
    }
    if (metadata.tags[0] === "hide-output") {
      return (
        <div className="output">
          <Code language={`python`} code={code.join("")} />
        </div>
      );
    }
    if (metadata.tags[0] === "hide") {
      return null;
    }
  }
  return (
    <div className="output">
      <Code language={`python`} code={code.join("")} />
      {output_data}
    </div>
  );
}

function OutputMedia({ path }) {
  const media_path = path;
  // const media = require(media_path)
  return <div><img src={media_url + media_path} className="mx-autok" /></div>;
}

export { Output, OutputMedia };
