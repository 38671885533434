import axios from "axios";
import { API_SERVER } from "../settings";

let api_url = API_SERVER + "api/";

function getPostList() {
  return axios.get(api_url + "blog/list");
}

function getPostDetail(slug) {
  return axios.get(api_url + "blog/" + slug);
}

export { getPostList, getPostDetail };
