function Signoff() {
  return (
    <div className="grid grid-cols-2 mt-8 pt-8 border-t-2 justify-between">
      <div className="my-auto">
        <div className="flex flex-wrap my-auto">
          <div>
            <a href="https://www.linkedin.com/in/cbalona/">
              <img
                src="/static/media/LI-Bug.svg.original.svg"
                width="25"
                height="25"
              />
            </a>
          </div>
          <div className="pl-2 text-lg">
            <a href="https://www.linkedin.com/in/cbalona/">Caesar Balona</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Signoff };
