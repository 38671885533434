import { Title, BlockQuote, UnorderedList } from "../content/typography";
import Page from "../layout/page";
import { Signoff } from "../components/Signoff";

function About() {
  const prev_research = [
    <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3697256">
      The Actuary and IBNR Techniques: A Machine Learning Approach
    </a>,
    <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4543652">
      ActuaryGPT: Applications of Large Language Models to Insurance and Actuarial Work
    </a>,
  ];
  const upcoming_research = [
    "Deep Learning with Whittaker and Henderson",
    "Some untitled thoughts",
  ];
  const upcoming_talks = [
    ""
  ]

  const quote =
    "I told my son: you don’t need to be in the top three in your class, \
    being in the middle is fine, so long as your grades aren’t too bad. Only this \
    kind of person [a middle-of-the-road student] has enough free time to learn \
    other skills.";
  const author = "Jack Ma";

  return (
    <Page>
      <div className="max-w-3xl mx-auto">
        <Title>
          About Modern<b>Actuary</b>
        </Title>
        <p className="text-justify">
          Modern Actuary is a personal blog that I run to collect all my thoughts
          and ideas around modernising the actuarial toolkit and anything else I
          find interesting.
        </p>
        <br />
        <p className="text-justify">
          Who am I? I am <a href="https://www.linkedin.com/in/cbalona/">Caesar Balona</a>. I am generally curious about things and
          enjoy learning. I also have a keen interest in computers and science.
          While growing up, this meant I neglected my school work in favour of
          playing computer games and spending all day on the internet learning
          random things.
        </p>
        <p>Time well spent, according to Jack Ma:</p>
        <BlockQuote quote={quote} author={author} />
        <p className="text-justify">
          Until recently I headed up the Data Science team at QED Actuaries & Consultants. My
          focus was on researching and applying data science techniques to actuarial
          problems and engineering software to improve and empower actuarial processes.
          I led engagements that included deriving industry wide mortality tables using deep neural networks,
          and developing a wholistic framework for the development, maintenance, and assessment of
          automated underwriting systems.
          More traditionally, I have led projects on reserving, pricing and capital management
          and often serve as a sounding board and thought leader on traditional actuarial
          projects. I qualified as a Fellow of the Actuarial Society of South Africa in 2019 and
          obtained my Practicing Certificate in Short Term Insurance in 2022.
        </p>
        <br />
        <p>
          <strong>Upcoming Research</strong>
        </p>
        <UnorderedList list={upcoming_research} />
        {/* <br />
        <p>
          <strong>Upcoming Talks</strong>
        </p>
        <UnorderedList list={upcoming_talks} /> */}
        <br />
        <p>
          <strong>Previous Research</strong>
        </p>
        <UnorderedList list={prev_research} />
        <Signoff />
      </div>
    </Page>
  );
}

export default About;
