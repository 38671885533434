import { useState } from "react";
import { Redirect, Link } from 'react-router-dom';

import { Title, Heading } from "../content/typography"

import Page from "../layout/page";


function NotFoundContent({ timeLeft }) {
  return (
    <Page>
      <Title>404 - Not Found!</Title>
      <center>
        <p>Redirecting in {timeLeft}s</p>
        <Heading level={3}>
          <Link to="/">
            Go Home
          </Link>
        </Heading>
      </center>
    </Page>
  )
}


function NotFound() {
  const [time, setTime] = useState(5);

  setTimeout(() => {
    setTime(time - 1)
  }, 1000)

  return (
    (time === 0) ? <Redirect to="/" /> : <NotFoundContent timeLeft={time}/>
  )
};

export default NotFound;