import { Link } from "react-router-dom";

import { Title, Heading } from "../content/typography";
import Page from "../layout/page";
import { Signoff } from "../components/Signoff";

function AppList() {
  return (
    <Page>
      <Title>Interactive React Apps</Title>
      <p>Here you can find interactive apps. Some related to blog posts, some just for fun.</p>
      <br />
      <div className="grid grid-cols-2 gap-4 overflow-hidden p-4">
        <div className="transition duration-500 ease-in-out border rounded-lg shadow-lg p-4 transform hover:-translate-y-1 hover:scale-105">
          <Heading level={1}>
            <Link
              to={{
                pathname: `apps/chainladder`,
              }}
            >
              Chainladder Reserving
            </Link>
          </Heading>
          <small>
            <p>
              This app interfaces directly to the{" "}
              <a href="https://chainladder-python.readthedocs.io/">
                chainladder
              </a>{" "}
              python library, allowing you to see it work in real time.
            </p>
          </small>
        </div>
        <div className="transition duration-500 ease-in-out border rounded-lg shadow-lg p-4 transform hover:-translate-y-1 hover:scale-105">
          <Heading level={1}>
            <Link
              to={{
                pathname: `apps/reinsurance`,
              }}
            >
              Reinsurance Calculator
            </Link>
          </Heading>
          <small>
            <p>
            This is a proof of concept of a reinsurance calculation app. Click and drag to reorder the individual structures or the layers within the excess of loss structures. Easily add new structures or layers with one click. See in real time how your changes impact your net loss.
            </p>
          </small>
        </div>
      </div>
      <br />
      {/* <small>For those interested, the apps are using React as a frontend framework, with a custom built backend.</small> */}
      <Signoff />
    </Page>
  );
}

export default AppList;
