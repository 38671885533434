import { Draggable, Droppable } from "react-beautiful-dnd";

import { Title, Heading } from "../../content/typography";

import Layer from "./Layer.js"

function XoL({ key, treaty, layers, index, addXolLayer, deleteLayer, updateLayer }) {
  return (
    <Droppable droppableId={treaty.id} type="xol">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`p-4 ${snapshot.isDraggingOver ? "bg-gray-200" : ""}`}
        >
          {layers.map((layer, index) => <Layer key={layer.id} treaty={treaty} layer={layer} index={index} deleteLayer={deleteLayer} updateLayer={updateLayer} />)}
          {provided.placeholder}
          <button
            className="border rounded-md py-1 w-full font-bold text-sm text-gray-500 capitalize"
            onClick={(e) => addXolLayer(treaty.id, e)}
          >
            + Layer
          </button>
        </div>
        
      )}
    </Droppable>
  )
}

function QS({ key, treaty, layers, index, updateLayer }) {
  return (
    <Droppable droppableId={treaty.id} type="qs">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`p-4 ${snapshot.isDraggingOver ? "bg-gray-200" : ""}`}
        >
          {layers.map((layer, index) => <Layer key={layer.id} layer={layer} index={index} isDragDisabled={true} updateLayer={updateLayer} />)}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default function Treaty({ key, treaty, layers, index, deleteTreaty, addXolLayer, deleteLayer, updateLayer }) {

  return (
    <Draggable draggableId={treaty.id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="w-full flex border rounded-lg shadow-md bg-white"
        >
          <div {...provided.dragHandleProps} className="rounded-lg w-36 text-white font-medium bg-gray-900 flex flex-col justify-center items-center">
            <div>{treaty.title}</div>
            <div><button onClick={(e) => deleteTreaty(treaty.id, e)} id={treaty.id}><img src="/static/media/delete.svg" width="15"></img></button></div>
          </div>
          <div className="flex-1">
            {
              treaty.type === "xol"
                ? <XoL key={key} treaty={treaty} layers={layers} index={index} addXolLayer={addXolLayer} deleteLayer={deleteLayer} updateLayer={updateLayer}></XoL>
                : <QS key={key} treaty={treaty} layers={layers} index={index} updateLayer={updateLayer}></QS>
            }
          </div>
        </div>
      )}
    </Draggable>
  )

}

// export default function Treaty({ key, treaty, layers, index }) {
//   return (
//     <Draggable draggableId={treaty.id} index={index}>
//       {(provided) => (
//         <div
//           {...provided.draggableProps}
//           ref={provided.innerRef}
//           className="w-full flex border rounded-lg shadow-md bg-white"
//         >
//           <div {...provided.dragHandleProps} className="rounded-lg w-48 text-white font-medium bg-gray-600 flex flex-col justify-center items-center">
//             {treaty.title}
//           </div>
//           <div className="flex-1">
//             <Droppable droppableId={treaty.id} type="layer">
//               {(provided, snapshot) => (
//                 <div
//                   {...provided.droppableProps}
//                   ref={provided.innerRef}
//                   className={`p-4 ${snapshot.isDraggingOver ? "bg-gray-200" : ""}`}
//                 >
//                   {layers.map((layer, index) => <Layer key={layer.id} layer={layer} index={index} />)}
//                   {provided.placeholder}
//                 </div>
//               )}
//             </Droppable>
//           </div>
//         </div>
//       )}
//     </Draggable>
//   )
// }