import NavBar from "../components/NavBar";

function Page({ children }) {
  return (
    <div className="_page">
      <NavBar />
      <div className="container mt-6 mx-auto max-w-5xl px-4 pb-16 mb-16">
        {children}
      </div>
    </div>
  );
}

export default Page;
