import { Link } from "react-router-dom";

function NavBar() {
  return (
    <nav className="bg-gray-800">
      <div className="max-w-5xl mx-auto px-2">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-stretch justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <div className="text-2xl text-white">
                  Modern<b>Actuary</b>
                </div>
              </Link>
            </div>
          </div>
          <div className="block ml-6">
            <div className="flex space-x-4">
              <Link to="/">
                <div
                  className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                  aria-current="page"
                >
                  Home
                </div>
              </Link>
              {/* <Link to="/blog"><div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Blog</div></Link> */}
              <Link to="/apps">
                <div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Apps
                </div>
              </Link>
              <Link to="/projects">
                <div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Projects
                </div>
              </Link>
              <Link to="/consulting">
                <div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Consulting
                </div>
              </Link>
              <Link to="/about">
                <div className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  About
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
