import { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router";

import { Title, Heading, Paragraph, BlockQuote } from "../content/typography";
import { Output, OutputMedia } from "../components/Output";
import { Signoff } from "../components/Signoff";
import { getPostDetail } from "../api/blog";

import Page from "../layout/page";

function streamFieldSwitch(cell, slug) {
  switch (cell.cell_type) {
    case "markdown":
      if (cell.metadata.tags) {
        return markdownFieldSwitch(cell);
      } else {
        return <Paragraph>{cell.source.join("<br />")}</Paragraph>;
      }
    case "code":
      if (cell.metadata.tags) {
        return codeFieldSwitch(cell, slug);
      } else {
        return (
          <Output
            code={cell.source}
            output={cell.outputs}
            metadata={cell.metadata}
          />
        );
      }
    default:
      return <p>{cell.source.join("<br />")}</p>;
  }
}

function codeFieldSwitch(cell, slug) {
  switch (cell.metadata.tags[0]) {
    case "media":
      const path = cell.metadata.tags[1];
      return <OutputMedia path={slug + "/" + path} />;
    case "hide":
      return null;
    default:
      return (
        <Output
          code={cell.source}
          output={cell.outputs}
          metadata={cell.metadata}
        />
      );
  }
}

function markdownFieldSwitch(cell) {
  switch (cell.metadata.tags[0]) {
    case "title":
      return <Title>{cell.source}</Title>;
    case "heading1":
      return <Heading level={1}>{cell.source}</Heading>;
    case "heading2":
      return <Heading level={2}>{cell.source}</Heading>;
    case "heading3":
      return <Heading level={3}>{cell.source}</Heading>;
    case "blockquote":
      return <BlockQuote quote={cell.source[0]} author={cell.source[1]} />;
    default:
      return <Paragraph>{cell.source.join("<br />")}</Paragraph>;
  }
}

function PostBody({ cells, slug }) {
  if (cells) {
    var formatted_cells = cells.map((cell) => streamFieldSwitch(cell, slug));
  } else {
    var formatted_cells = "Loading";
  }

  return <div className=" mx-auto mt-4 prose max-w-3xl">{formatted_cells}</div>;
}

function PostDetail() {
  const [postDetail, setPostDetail] = useState([]);
  const [notFound, setNotFound] = useState(false);
  let { slug } = useParams();

  useEffect(() => {
    getPostDetail(slug)
      .then((res) => {
        setPostDetail(res.data);
      })
      .catch((err) => {
        setNotFound(true);
      });
  }, [slug]);

  if (notFound) {
    return <Redirect to="/404NotFound" />;
  }

  return (
    <Page>
      <PostBody cells={postDetail.cells} slug={slug} />
      <Signoff />
    </Page>
  );
}

export default PostDetail;
