function TextInputForm({ label, name, value, onChange }) {
  return (
    <div id={`${name}_form_item`} className="flex">
      <label className="py-1 pl-2 w-1/2 border rounded-l-md bg-gray-100 font-medium text-sm text-gray-500 capitalize">
        {label}
      </label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        className="py-1 pr-2 w-1/2 border border-l-0 rounded-r-md text-sm text-gray-500 text-right"
      />
    </div>
  );
}

function SelectForm({ label, name, value, onChange }) {
  return (
    <div id={`${name}_form_item`} className="flex">
      <label className="py-1 pl-2 w-1/2 border rounded-l-md bg-gray-100 font-medium text-sm text-gray-500 capitalize">
        {label}
      </label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className="border border-gray-500 rounded-md w-1/2 py-1 font-medium text-right"
      >
        <option value="raa">RAA</option>
        <option value="abc">ABC</option>
      </select>
    </div>
  );
}

function SliderForm({ min, max, step = 1, value, name, onChange }) {
  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        className="slider w-full"
        name={name}
        onChange={onChange}
      ></input>
    </div>
  );
}

function CheckboxForm({ checked, name, onChange }) {
  return (
    <div>
      <input type="checkbox" name={name} checked={checked} onChange={onChange} />
    </div>
  )
}

export { TextInputForm, SelectForm, SliderForm, CheckboxForm };
