const initialData = {
  grossLosses: [10000000, 10000000],
  totalGrossLosses: 20000000,
  treaties: {
    'treaty-1': {
      id: 'treaty-1',
      title: 'QuotaShare',
      type: 'qs',
      layerIds: ['layer-1'],
    },
    'treaty-2': {
      id: 'treaty-2',
      title: 'Cat XoL',
      type: 'xol',
      layerIds: ['layer-2', 'layer-3', 'layer-4'],
    },
  },
  layers: {
    'layer-1': {
      id: 'layer-1',
      type: 'qs',
      cession: 20,
    },
    'layer-2': {
      id: 'layer-2',
      type: 'xol',
      attachment: 200000,
      width: 300000,
      deductible: 0,
      rate_on_line: 10,
      reinstatements: 1,
      free_reinstatements: 0,
    },
    'layer-3': {
      id: 'layer-3',
      type: 'xol',
      attachment: 500000,
      width: 500000,
      deductible: 0,
      rate_on_line: 5,
      reinstatements: 1,
      free_reinstatements: 0,
    },
    'layer-4': {
      id: 'layer-4',
      type: 'xol',
      attachment: 1000000,
      width: 1000000,
      deductible: 0,
      rate_on_line: 2,
      reinstatements: 1,
      free_reinstatements: 0,
    },
  },
  // Facilitate reordering of the columns
  treatyOrder: ['treaty-1', 'treaty-2'],
};
  
  export default initialData;
  