import { Switch, Route } from "react-router-dom";

import PostList from "./pages/PostList";
import PostDetail from "./pages/PostDetail";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Consulting from "./pages/Consulting";
import NotFound from "./pages/NotFound";

import AppList from "./apps/AppsList"
import Chainladder from "./apps/chainladder/Chainladder";
import HMD from "./apps/hmd/HMD";
import Reinsurance from "./apps/reinsurance/Reinsurance";

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <PostList />
      </Route>
      <Route exact path="/blog">
        <PostList />
      </Route>
      <Route exact path="/blog/:slug">
        <PostDetail />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/projects">
        <Projects />
      </Route>
      <Route exact path="/consulting">
        <Consulting />
      </Route>
      <Route exact path="/apps">
        <AppList />
      </Route>
      <Route exact path="/apps/chainladder">
        <Chainladder />
      </Route>
      <Route exact path="/apps/reinsurance">
        <Reinsurance />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
}

export default App;
