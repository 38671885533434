import dompurify from "dompurify";

function Table({ children }) {
  const sanitized = dompurify.sanitize(children, {FORCE_BODY: true})
  return (
    <div
      className="table-auto overflow-x-auto my-4"
      dangerouslySetInnerHTML={{ __html: sanitized }}
    />
  );
}

export default Table;