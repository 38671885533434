import { Title, BlockQuote, UnorderedList } from "../content/typography";
import Page from "../layout/page";
import { Signoff } from "../components/Signoff";

function Consulting() {
  return (
    <Page>
      <div className="max-w-3xl mx-auto">
        <Title>
          Consulting, contracting, training, etc.
        </Title>
        <p className="text-justify">
          If you have an interesting project that you think would benefit from my skills
          please reach out to me on my LinkedIn (link at the bottom). I can assist with <b>consulting </b>
          on general projects, <b>contracting</b> to write code to meet your specifications, and
          <b> training</b> you or your staff.
        </p>
        <br />
        <p className="text-justify">
          If you need help with data analysis, machine learning, web development, and so on,
          I can help. I have helped clients investigate and spin up proof of concepts
          for new ideas using machine learning and AI, developed web apps, converted excel
          processes to python, and generally advised and assisted on how to start using data
          or automate workflows. If you are unsure, reach out and we can discuss.
        </p>
        <br />
        <p className="text-justify">
          Contact me for my pricing and an estimate based on the work. I do this in my spare time, so if
          the work is urgent I can still do it, but the rate will increase. If you are an NGO, charity,
          or the work is education based to students that are financially disadvantaged, I am open to
          discuss reduced fees.
        </p>
        <Signoff />
      </div>
    </Page>
  );
}

export default Consulting;
