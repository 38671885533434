import axios from "axios";
import { API_SERVER } from "../../settings";
import { useEffect, useState } from "react";

import { Title } from "../../content/typography";
import Table from "../../components/Table";
import { SliderForm, CheckboxForm } from "../../components/Forms";
import { Signoff } from "../../components/Signoff";
import Page from "../../layout/page";

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryTheme,
  VictoryBar,
} from "victory";

let api_url = API_SERVER + "api/";

function SelectForm({ label, name, value, onChange }) {
  return (
    <div id={`${name}_form_item`} className="flex">
      <label className="py-1 pl-2 w-1/2 border rounded-l-md bg-gray-100 font-medium text-sm text-gray-500 capitalize">
        {label}
      </label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className="border border-gray-500 rounded-md w-1/2 py-1 font-medium text-right"
      >
        <option value="raa">RAA</option>
        <option value="abc">ABC</option>
        <option value="UKMotor">UKMotor</option>
        <option value="GenIns">GenIns</option>
      </select>
    </div>
  );
}

function LDFChart({ data, name }) {
  if (data === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <VictoryChart theme={VictoryTheme.material} width={600} height={180}>
      <VictoryAxis />
      <VictoryLine data={data} x="index" y={name} />
    </VictoryChart>
  );
}

function IBNRChart({ data, name, ydomain }) {
  if (data === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      width={680}
      height={180}
      domain={{ y: ydomain }}
      domainPadding={{ x: 25 }}
      padding={{ left: 80, right: 20, top: 20, bottom: 40 }}
    >
      <VictoryBar data={data} x="index" y={name} />
    </VictoryChart>
  );
}

function Chainladder() {
  const [datasetKey, setDatasetKey] = useState("raa");
  const [dataset, setDataset] = useState({});
  const development_initial_state = {
    n_periods: 5,
    drop_high: false,
    drop_low: false,
  };
  const [development, setDevelopment] = useState(development_initial_state);
  const dev_ui_initial_state = {
    n_periods_min: 3,
    n_periods_max: 10,
    ibnr_graph_max: 50000,
  };
  const [devUIComponents, setDevUIComponents] = useState(dev_ui_initial_state);
  const [fullTriangle, setFullTriangle] = useState("Loading...");
  const [IBNRTable, setIBNRTable] = useState("Loading...");
  const [IBNR, setIBNR] = useState([]);
  const [LDF, setLDF] = useState([]);

  const ydomain = [0, devUIComponents.ibnr_graph_max];

  useEffect(() => {
    function resetUI() {
      setDevelopment(development_initial_state);
    }
    
    function getDataset() {
      axios
        .post(api_url + "apps/chainladder/get_dataset", { dataset: datasetKey })
        .then((res) => {
          setDataset(res.data.table);
          setDevUIComponents(res.data.dev_ui_components);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          resetUI();
        });
    }
    getDataset();
  }, [datasetKey]);

  useEffect(() => {
    function predict() {
      const payload = {
        development: development,
        dataset: { dataset: datasetKey },
      };
      axios
        .post(api_url + "apps/chainladder/predict", payload)
        .then((res) => {
          setFullTriangle(res.data.full_triangle);
          setIBNRTable(res.data.ibnr_table);
          setLDF(res.data.ldf);
          setIBNR(res.data.ibnr);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    predict();
  }, [datasetKey, development]);



  function updateDatasetKey(e) {
    const value = e.target.value;
    setDatasetKey(value);
  }

  function updateDevelopment(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setDevelopment({
      ...development,
      [e.target.name]: value,
    });
  }


  return (
    <Page>
      <Title>Chainladder Reserving</Title>
      <p>
        This app interfaces directly to the{" "}
        <a href="https://chainladder-python.readthedocs.io/">chainladder</a>{" "}
        python library, allowing you to see it work in real time.
      </p>
      <p>Currently, only the Basic Chainladder is supported. More functionality will be built in over time.</p>
      <br />
      <SelectForm
        label="Dataset"
        name="dataset"
        value={datasetKey}
        onChange={updateDatasetKey}
      />
      <Table>{fullTriangle}</Table>
      <div className="grid grid-cols-6 grid-rows-2 gap-1">
        <div className="col-span-4">Number of Development Periods</div>
        <div className="col-span-1">Drop High LDFs?</div>
        <div className="col-span-1">Drop Low LDFs?</div>
        <div className="col-span-3">
          <SliderForm
            min={devUIComponents.n_periods_min}
            max={devUIComponents.n_periods_max}
            step={1}
            value={development.n_periods}
            onChange={updateDevelopment}
            name="n_periods"
          />
        </div>
        <div className="text-center">{development.n_periods}</div>
        <div className="text-center">
          <CheckboxForm
            checked={development.drop_high}
            name="drop_high"
            onChange={updateDevelopment}
          />
        </div>
        <div className="text-center">
          <CheckboxForm
            checked={development.drop_low}
            name="drop_low"
            onChange={updateDevelopment}
          />
        </div>
      </div>
      <div className="grid grid-cols-6 grid-rows-2 gap-0">
        <div className="row-span-2">
          <Table>{IBNRTable}</Table>
        </div>
        <div className="col-span-5 mx-2">
          <LDFChart data={LDF} name="ldf" />
        </div>
        <div className="col-span-5 mx-2">
          <IBNRChart data={IBNR} name="ibnr" ydomain={ydomain} />
        </div>
      </div>
      {/* <Table>{fullTriangle}</Table> */}
      <div className="text-right pt-2 text-xs text-gray-400">Version 0.1.0 - 27 May 2021</div>
      <br />
      <br />
      <Signoff />
    </Page>
  );
}

export default Chainladder;
