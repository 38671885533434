import { useEffect } from "react";

import marked from "marked";
import dompurify from "dompurify";

import Prism from "prismjs";
import 'prismjs/components/prism-python';

function Title({ children }) {
  return <h1 className="font-serif text-5xl text-center py-10">{children}</h1>;
}

function Heading({ level, children }) {
  return <h1 className={`font-serif text-${4-level}xl pt-8`}>{children}</h1>;
}

function Paragraph({ children }) {
  const parsed = marked(children);
  const sanitized = dompurify.sanitize(parsed);
  return (
    <div className="mt-4 text-justify" dangerouslySetInnerHTML={{ __html: sanitized }} />
  );
}

function BlockQuote({ quote, author }) {
  const sanitized_quote = dompurify.sanitize(quote);
  const sanitized_author = dompurify.sanitize(author);
  return (
    <div className="border-l-4 my-4 mx-8 py-2 pl-8 text-justify">
      <div dangerouslySetInnerHTML={{ __html: sanitized_quote}} />
      <div className="text-xs mt-1 ml-2" dangerouslySetInnerHTML={{ __html: sanitized_author}} />
    </div>
  );
}

function Code({ language, code }) {
  useEffect(() => {
      setTimeout(() => Prism.highlightAll(), 0)
    }, []);

  return (
    <div className="pt-4 text-xs">
      <pre>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
}

function UnorderedList({ list }) {
  return (
    <ul className="list-disc list-inside ml-4">
      {list.map(item => (
        <li>{item}</li>
      ))}
    </ul>
  )
}

export { Title, Heading, Paragraph, BlockQuote, Code, UnorderedList };
