import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getPostList } from "../api/blog";

import { Heading, Paragraph } from "../content/typography"
import Page from "../layout/page";
import { Signoff } from "../components/Signoff";

function PostListItem({ postItem }) {
  return (
    <div className="bg-white border-gray-200 border-b-2 pb-10 flex flex-1 flex-col overflow-hidden">
      <Heading level={1}>
        <Link
          to={{
            pathname: `blog/${postItem.slug}`,
            // state: { id: postItem.id },
          }}
        >
          {postItem.title}
        </Link>
      </Heading>
      <small>
        {postItem.author} &bull; {postItem.date}
      </small>
      <Paragraph>{postItem.summary}</Paragraph>
    </div>
  );
}

function PostList() {
  const [postList, setPostList] = useState({});

  useEffect(() => {
    getPostList()
      .then((res) => {
        setPostList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);


  if (postList.notebooks) {
    var posts = postList.notebooks.map((post) => (
      <PostListItem postItem={post} />
    ));
  }

  return <Page><div className="max-w-3xl mx-auto">{posts}</div><Signoff /></Page>;
}

export default PostList;
