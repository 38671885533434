import { Draggable } from "react-beautiful-dnd"
import NumberFormat from 'react-number-format';

export default function Layer({ key, treaty, layer, index, isDragDisabled, deleteLayer, updateLayer }) {

  if (layer.type === 'qs') {
    return (
      <Draggable key={key} draggableId={layer.id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (

          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`flex mb-1 min-h-16 ${snapshot.isDragging ? 'bg-green-200' : 'bg-white'}`}
          >
            <div className="flex w-23/24">
              <div className="py-1 border rounded-l-md w-1/8 flex items-center justify-center bg-gray-100 font-medium text-sm text-gray-500 capitalize">
                Cession
              </div>
              <div className="w-5/5 border-b border-t text-sm flex flex-auto items-center">
                <NumberFormat
                  defaultValue={`${layer.cession}`}
                  onValueChange={(e) => updateLayer(layer.id, "cession", e)}
                  className="py-1 pr-1 text-right"
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return (floatValue >= 0);
                  }}
                />
                %
              </div>
            </div>
            <div className="w-1/24 px-1 border-t border-b border-r rounded-r-md"> </div>
          </div>
        )}
      </Draggable>
    )
  }

  if (layer.type === 'xol') {
    return (
      <Draggable key={key} draggableId={layer.id} index={index}>
        {(provided, snapshot) => (

          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`flex mb-1 ${snapshot.isDragging ? 'bg-green-200' : 'bg-white'}`}
          >
            <div className="flex w-23/24">
              <div className="py-1 border rounded-l-md w-1/8 flex items-center justify-center bg-gray-100 font-medium text-sm text-gray-500 capitalize">
                {`Layer ${index + 1}`}
              </div>
              <div className="grid border-t border-b grid-cols-6 w-7/8">
                <div className="py-1 pr-1 font-medium text-right text-xs">
                  Attachment:
                </div>
                <NumberFormat
                  defaultValue={`${layer.attachment}`}
                  onValueChange={(e) => updateLayer(layer.id, "attachment", e)}
                  className="py-1 pr-1 text-right text-xs"
                  thousandSeparator={true}
                  prefix={'R '}
                  decimalScale={0}
                />
                <div className="py-1 pr-1 font-medium text-right text-xs">
                  Width:
                </div>
                <NumberFormat
                  defaultValue={`${layer.width}`}
                  onValueChange={(e) => updateLayer(layer.id, "width", e)}
                  className="py-1 pr-1 text-right text-xs"
                  thousandSeparator={true}
                  prefix={'R '}
                  decimalScale={0}
                />
                <div className="py-1 pr-1 font-medium text-right text-xs">
                  Deductible:
                </div>
                <NumberFormat
                  defaultValue={`${layer.deductible}`}
                  onValueChange={(e) => updateLayer(layer.id, "deductible", e)}
                  className="py-1 pr-1 text-right text-xs"
                  suffix={'%'}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return (floatValue >= 0);
                  }}
                  decimalScale={2}
                  />
                <div className="py-1 pr-1 font-medium text-right text-xs">
                  Rate-on-Line:
                </div>
                <NumberFormat
                  defaultValue={`${layer.rate_on_line}`}
                  onValueChange={(e) => updateLayer(layer.id, "rate_on_line", e)}
                  className="py-1 pr-1 text-right text-xs"
                  suffix={'%'}
                  decimalScale={2}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return (floatValue >= 0);
                  }}
                  />
                <div className="py-1 pr-1 font-medium text-right text-xs">
                  Reinstatements:
                </div>
                <NumberFormat
                  defaultValue={`${layer.reinstatements}`}
                  onValueChange={(e) => updateLayer(layer.id, "reinstatements", e)}
                  className="py-1 pr-1 text-right text-xs"
                  decimalScale={0}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return (floatValue >= 0);
                  }}
                  />
                <div className="py-1 pr-1 font-medium text-right text-xs">
                  Free Reinst.:
                </div>
                <NumberFormat
                  defaultValue={`${layer.free_reinstatements}`}
                  onValueChange={(e) => updateLayer(layer.id, "free_reinstatements", e)}
                  className="py-1 pr-1 text-right text-xs"
                  decimalScale={0}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return (floatValue >= 0);
                  }}
                  />
              </div>
              
            </div>
            <button
              onClick={(e) => deleteLayer(treaty.id, layer.id, e)}
              className="w-1/24 px-1 border-t border-b border-r rounded-r-md"
            >
              <img src="/static/media/delete.svg" width="15"></img>
            </button>
          </div>
        )}
      </Draggable>
    )
  }

  return (
    <Draggable key={key} draggableId={layer.id} index={index}>
      {(provided, snapshot) => (

        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`flex mb-1 ${snapshot.isDragging ? 'bg-green-200' : 'bg-white'}`}
        >
          <div className="py-1 pl-2 w-1/4 border rounded-l-md bg-gray-100 font-medium text-sm text-gray-500 capitalize">
            text
          </div>
          <div className="border border-gray-500 rounded-md w-3/4 py-1 font-medium text-right">
            {layer.content}
          </div>
        </div>
      )}
    </Draggable>
  )
}