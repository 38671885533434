import axios from "axios";
import { API_SERVER } from "../../settings";
import { useEffect, useState } from "react";

import { Title } from "../../content/typography";
import { Signoff } from "../../components/Signoff";
import Page from "../../layout/page";

import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryTheme,
} from "victory";

let api_url = API_SERVER + "api/";

function CountrySelectForm({ label, name, value, options, onChange }) {
  return (
    <div id={`${name}_form_item`} className="flex">
      <label className="py-1 pl-2 w-1/2 border rounded-l-md bg-gray-100 font-medium text-sm text-gray-500 capitalize">
        {label}
      </label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className="border border-gray-500 rounded-md w-1/2 py-1 font-medium text-right"
      >
        {options.map(function (option) {
          return (<option value={option}>{option}</option>)
        })}
      </select>
    </div>
  );
}

function YearSelectForm({ label, name, value, start_year, end_year, onChange }) {

  var yearRange = [];
  for (var i = start_year; i <= end_year; i++) {
    yearRange.push(i);
  }

  return (
    <div id={`${name}_form_item`} className="flex">
      <label className="py-1 pl-2 w-1/2 border rounded-l-md bg-gray-100 font-medium text-sm text-gray-500 capitalize">
        {label}
      </label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className="border border-gray-500 rounded-md w-1/2 py-1 font-medium text-right"
      >
        {yearRange.map(function (year) {
          return (<option value={year}>{year}</option>)
        })}
      </select>
    </div>
  );
}

// function MortalityChart({ data }) {
//   const [selectedDomain, setSelectedDomain] = useState({})
//   const [zoomDomain, setZoomDomain] = useState({})

//   if (data === undefined) {
//     return <div>Loading...</div>;
//   }

//   function handleZoom(domain) {
//     setSelectedDomain(domain)
//   }

//   function handleBrush(domain) {
//     setZoomDomain(domain)
//   }

//   return (
//     <div>
//       <VictoryChart
//         theme={VictoryTheme.material}
//         width={600}
//         height={300}
//         domainPadding={{ x: 25 }}
//         padding={{ left: 35, right: 10, top: 20, bottom: 25 }}
//         containerComponent={
//           <VictoryZoomContainer
//             zoomDimension="x"
//             zoomDomain={zoomDomain}
//             onZoomDomainChange={handleZoom}
//           />
//         }
//       >
//         <VictoryAxis style={{ ticks: { stroke: "grey", size: 5 } }} />
//         <VictoryAxis dependentAxis domain={{ x: [0, 100], y: [0, 1] }} />
//         <VictoryLine data={data} x="Age" y="qx" />
//       </VictoryChart>

//       <VictoryChart
//       theme={VictoryTheme.material}
//       width={600}
//       height={300}
//       domainPadding={{ x: 25 }}
//       padding={{ left: 35, right: 10, top: 20, bottom: 25 }}
//       containerComponent={
//         <VictoryBrushContainer
//           brushDimension="x"
//           brushDomain={selectedDomain}
//           onBrushDomainChange={handleBrush}
//         />
//       }
//       >
//       <VictoryAxis style={{ ticks: { stroke: "grey", size: 5 } }} />
//       <VictoryAxis dependentAxis domain={{ x: [0, 100], y: [0, 1] }} />
//       <VictoryLine data={data} x="Age" y="qx" />
//       </VictoryChart>
//     </div>
    
//   );
// }

function MortalityChart({ data }) {
  if (data === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      width={600}
      height={300}
      domainPadding={{ x: 25 }}
      padding={{ left: 35, right: 10, top: 20, bottom: 25 }}
    >
      <VictoryAxis style={{ ticks: { stroke: "grey", size: 5 } }} />
      <VictoryAxis dependentAxis domain={{x: [0, 100], y: [0, 1]}} />
      <VictoryLine data={data} x="Age" y="qx" />
    </VictoryChart>
  );
}

function HMD() {
  const [countryList, setCountryList] = useState([]);
  const [countryKey, setCountryKey] = useState("AUS");
  const country_meta_initial_state = {
    start_year: 1960,
    end_year: 2018,
  };
  const [countryMeta, setCountryMeta] = useState(country_meta_initial_state)
  const [year, setYear] = useState(country_meta_initial_state.end_year)
  const [countryData, setCountryData] = useState();

  useEffect(() => {
    function getCountryList() {
      axios
        .post(api_url + "apps/hmd/get_country_list")
        .then((res) => {
          setCountryList(res.data.countries);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          resetUI();
        });
    }
    getCountryList();
  }, []);

  useEffect(() => {
    function getCountryMeta() {
      axios
        .post(api_url + "apps/hmd/get_country_meta", { dataset: countryKey })
        .then((res) => {
          setCountryMeta(res.data);
          setYear(res.data.end_year)
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          resetUI();
        });
    }
    getCountryMeta();
  }, [countryKey]);

  useEffect(() => {
    function getCountryData() {
      axios
        .post(api_url + "apps/hmd/get_country_data", { dataset: countryKey, year: year })
        .then((res) => {
          setCountryData(res.data.table);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          resetUI();
        });
    }
    getCountryData();
  }, [countryKey, year]);


  function updateCountryKey(e) {
    const value = e.target.value;
    setCountryKey(value);
  }

  function updateYear(e) {
    const value = e.target.value;
    setYear(value);
  }

  function resetUI() {
  }

  return (
    <Page>
      <Title>Human Mortality Database</Title>
      <p>
        The < a href="https://www.mortality.org/">Human Mortality Database (HMD)</a> contains mortality data for over 40 countries. This app allows interaction with the basic 1x1 mortality data. I plan to add mortality projections in the near future.</p>
      <br />
      <div className="grid grid-cols-2 grid-rows-1 gap-1">
        <CountrySelectForm
          label="Country"
          name="country"
          value={countryKey}
          options={countryList}
          onChange={updateCountryKey}
        />
        <YearSelectForm
          label="Year"
          name="year"
          value={year}
          start_year={countryMeta.start_year}
          end_year={countryMeta.end_year}
          onChange={updateYear}
        />
      </div>

      {/* <Table>{countryData}</Table> */}
      <MortalityChart data={countryData} name="countryMortality" />
      <div className="text-right pt-2 text-xs text-gray-400">Version 0.1.0 - 03 July 2021</div>
      <br />
      <br />
      <Signoff />
    </Page>
  );
}

export default HMD;
