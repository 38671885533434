import axios from "axios";
import { API_SERVER } from "../../settings";

import { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import NumberFormat from "react-number-format";

import { Title } from "../../content/typography";
import { Signoff } from "../../components/Signoff";
import Page from "../../layout/page";

import Treaty from "./Treaty.js";

import initialData from "./initial-data";

let api_url = API_SERVER + "api/";


function Reinsurance() {
  const [state, setState] = useState(initialData);
  const [enableGenerateLosses, setEnableGenerateLosses] = useState(true)
  const [distributionalAssumptions, setDistributionalAssumptions] = useState({ lambda_: 10, k: 7.5, theta: 1, multiple: 1000000 })
  const [statement, setStatement] = useState({ grossLosses: [1000000, 1000], netLosses: [10, 10], totalNetLosses: 20 })
  const [numSimulations, setNumSimulations] = useState(100);
  const [simRan, setSimRan] = useState(false)
  const [simResults, setSimResults] = useState({})
  const [enableUpload, setEnableUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null)

  function onDragEnd(result) {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "treaty") {
      const newTreatyOrder = Array.from(state.treatyOrder);
      newTreatyOrder.splice(source.index, 1);
      newTreatyOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...state,
        treatyOrder: newTreatyOrder,
      };
      setState(newState);
      return;
    }

    const start = state.treaties[source.droppableId];
    const finish = state.treaties[destination.droppableId];

    if (start === finish) {
      const newLayerIds = Array.from(start.layerIds);
      newLayerIds.splice(source.index, 1);
      newLayerIds.splice(destination.index, 0, draggableId);

      const newTreaty = {
        ...start,
        layerIds: newLayerIds,
      };

      const newState = {
        ...state,
        treaties: {
          ...state.treaties,
          [newTreaty.id]: newTreaty,
        }
      }

      setState(newState);
      return
    }

    const startLayerIds = Array.from(start.layerIds);
    startLayerIds.splice(source.index, 1);
    const newStart = {
      ...start,
      layerIds: startLayerIds,
    };

    const finishLayerIds = Array.from(finish.layerIds);
    finishLayerIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      layerIds: finishLayerIds
    };

    const newState = {
      ...state,
      treaties: {
        ...state.treaties,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }

    setState(newState);
    return
  };

  function addTreaty(treatyId, treatyType, newTreatyOrder) {

    if (treatyId === "") {
      return false
    } else if (treatyId) {
      var newTreaty = {
        id: treatyId,
        title: treatyId,
        type: treatyType,
        layerIds: [],
      }
      if (newTreatyOrder.indexOf(newTreaty.id) === -1) {
        newTreatyOrder.push(newTreaty.id)
      }

      if (treatyType === 'qs') {
        const newLayerId = `layer-${Object.keys(state.layers).length + 1}`
        const newLayer = {
          id: newLayerId,
          type: 'qs',
          cession: 50,
        }
        newTreaty = {
          ...newTreaty,
          layerIds: [newLayerId]
        }

        const newState = {
          ...state,
          treaties: {
            ...state.treaties,
            [newTreaty.id]: newTreaty
          },
          layers: {
            ...state.layers,
            [newLayerId]: newLayer,
          },
          treatyOrder: newTreatyOrder,
        }

        setState(newState);
        return
      }

      const newState = {
        ...state,
        treaties: {
          ...state.treaties,
          [newTreaty.id]: newTreaty
        },
        treatyOrder: newTreatyOrder,
      }

      setState(newState);
      return

    } else {
      return false
    }
  }

  function deleteTreaty(treatyId, e) {

    const newTreaties = state.treaties
    const newLayers = state.layers
    const newTreatyOrder = Array.from(state.treatyOrder);
    const deletedTreatyLayers = state.treaties[treatyId].layerIds

    // delete from treaties
    delete newTreaties[treatyId]

    // delete layers associated with deleted treaty
    deletedTreatyLayers.map((layerId) => { delete newLayers[layerId] })

    // remove from treaty order
    newTreatyOrder.splice(newTreatyOrder.indexOf(treatyId), 1)

    const newState = {
      ...state,
      treaties: newTreaties,
      layers: newLayers,
      treatyOrder: newTreatyOrder
    }

    setState(newState);
  }

  function addXOL() {
    const newTreatyOrder = Array.from(state.treatyOrder);
    const treatyType = "xol"
    var treatyId = prompt("Please enter name of new treaty", `treaty-${newTreatyOrder.length + 1}`);

    addTreaty(treatyId, treatyType, newTreatyOrder)
  }

  function addQS() {
    const newTreatyOrder = Array.from(state.treatyOrder);
    const treatyType = "qs"
    var treatyId = prompt("Please enter name of new treaty", `treaty-${newTreatyOrder.length + 1}`);

    addTreaty(treatyId, treatyType, newTreatyOrder)
  }

  function addXolLayer(treatyId, e) {
    const newTreaty = state.treaties[treatyId]
    const newLayers = state.layers
    const newLayerIds = Array.from(newTreaty.layerIds);
    const newLayerId = `layer-${Object.keys(newLayers).length + 1}`

    var newAttachment = 0
    var newWidth = 0
    if (newLayerIds.length > 0) {
      newAttachment = newLayers[newLayerIds[newLayerIds.length - 1]].attachment
        + newLayers[newLayerIds[newLayerIds.length - 1]].width
      newWidth = newLayers[newLayerIds[newLayerIds.length - 1]].width
    }

    const newLayer = {
      id: newLayerId,
      type: "xol",
      attachment: newAttachment,
      width: newWidth,
      deductible: 0,
      rate_on_line: 10,
      reinstatements: 0,
      free_reinstatements: 0,
    }

    newLayerIds.splice(newLayerIds.length, 1, newLayerId)
    newTreaty.layerIds = newLayerIds

    const newState = {
      ...state,
      treaties: {
        ...state.treaties,
        [treatyId]: newTreaty,
      },
      layers: {
        ...state.layers,
        [newLayerId]: newLayer,
      },
    }

    setState(newState)
  }

  function deleteLayer(treatyId, layerId, e) {

    const newTreaty = state.treaties[treatyId]
    const newLayers = state.layers
    const newLayerIds = Array.from(newTreaty.layerIds)

    // delete from layers
    delete newLayers[layerId]

    // delete layers from treaty
    newLayerIds.splice(newLayerIds.indexOf(layerId), 1)

    const newState = {
      ...state,
      treaties: {
        ...state.treaties,
        [treatyId]: {
          ...newTreaty,
          layerIds: newLayerIds,
        },
      },
      layers: newLayers,
    }

    setState(newState);
  }

  // useEffect(() => {
  //   function CalculateStatement() {
  //     var newStatement = {
  //       grossLoss: state.grossLoss,
  //       netLoss: state.grossLoss,
  //     }
  //     for (const treatyId of state.treatyOrder) {
  //       var treatyStatement = {
  //         grossLoss: newStatement.netLoss,
  //       }
  //       if (state.treaties[treatyId].type === "xol") {
  //         var treatyRecovery = 0
  //         for (const layerId of state.treaties[treatyId].layerIds) {
  //           const layer = state.layers[layerId]
  //           var layerRecovery = Math.max(Math.min(layer.width, treatyStatement.grossLoss - layer.attachment), 0)
  //           var layerBurn = layerRecovery / layer.width
  //           var layerPremium = layerBurn * layer.width * layer.rate_on_line / 100
  //           treatyRecovery = treatyRecovery + layerRecovery - layerPremium
  //         }
  //         treatyStatement.netLoss = treatyStatement.grossLoss - treatyRecovery
  //       } else {
  //         for (const layerId of state.treaties[treatyId].layerIds) {
  //           const layer = state.layers[layerId]
  //           var layerRecovery = layer.cession * 0.01 * treatyStatement.grossLoss
  //           treatyStatement.netLoss = treatyStatement.grossLoss - layerRecovery
  //         }
  //       }

  //       newStatement = {
  //         ...newStatement,
  //         netLoss: treatyStatement.netLoss,
  //         [treatyId]: treatyStatement
  //       }
  //     }
  //     setStatement(newStatement)

  //   }
  //   CalculateStatement();
  // }, [state]);

  function addGrossLoss() {
    var grossLosses = state.grossLosses
    grossLosses.push(0)
    const totalGrossLosses = (grossLosses.length > 0) ? grossLosses.reduce(reducer) : 0
    const newState = {
      ...state,
      grossLosses: grossLosses,
      totalGrossLosses: totalGrossLosses
    }
    setState(newState);
  }

  function removeGrossLoss() {
    var grossLosses = state.grossLosses
    grossLosses.splice(grossLosses.length - 1, 1)
    const totalGrossLosses = (grossLosses.length > 0) ? grossLosses.reduce(reducer) : 0
    const newState = {
      ...state,
      grossLosses: grossLosses,
      totalGrossLosses: totalGrossLosses
    }
    setState(newState);
  }

  function updateGrossLoss(e, index) {
    const grossLosses = Array.from(state.grossLosses);
    grossLosses.splice(index, 1);
    grossLosses.splice(index, 0, e.floatValue);

    const totalGrossLosses = (grossLosses.length > 0) ? grossLosses.reduce(reducer) : 0

    const newState = {
      ...state,
      grossLosses: grossLosses,
      totalGrossLosses: totalGrossLosses
    }
    setState(newState)
  }

  function updateLayer(layerId, name, e) {
    var newState = state
    var newLayer = newState.layers[layerId]
    if (state.layers[layerId].type === "qs") {
      newLayer = {
        ...newState.layers[layerId],
        [name]: e.floatValue
      }
    } else {
      newLayer = {
        ...newState.layers[layerId],
        [name]: e.floatValue
      }
    }
    newState = {
      ...newState,
      layers: {
        ...newState.layers,
        [layerId]: newLayer
      }
    }
    setState(newState);
  }

  useEffect(() => {
    function getStatement() {
      axios
        .post(api_url + "apps/ri/get_net_loss", state)
        .then((res) => {
          setStatement(res.data)
        })
        .catch((error) => {
          console.log(error);
        })
    }
    getStatement();
  }, [state]);

  function generateLossesSwitch() {
    if (enableGenerateLosses) {
      setEnableGenerateLosses(false)
    } else {
      setEnableGenerateLosses(true)
      setEnableUpload(false)
    }
  }

  // function uploadSwitch() {
  //   if (enableUpload) {
  //     setEnableUpload(false)
  //   } else {
  //     setEnableGenerateLosses(false)
  //     setEnableUpload(true)
  //   }
  // }

  function generateLosses() {
    axios
      .post(api_url + "apps/ri/generate_losses", distributionalAssumptions)
      .then((res) => {
        const grossLosses = res.data
        const totalGrossLosses = (grossLosses.length > 0) ? grossLosses.reduce(reducer) : 0
        const newState = {
          ...state,
          grossLosses: grossLosses,
          totalGrossLosses: totalGrossLosses
        }
        setState(newState)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function updateDistributionalAssumptions(param, e) {
    const newDistributionalAssumptions = {
      ...distributionalAssumptions,
      [param]: e.floatValue
    }
    setDistributionalAssumptions(newDistributionalAssumptions);
  }

  function updateNumSimulations(e) {
    setNumSimulations(e.floatValue);
  }

  function runSimulation() {
    axios
      .post(api_url + "apps/ri/run_simulation", { dbn: distributionalAssumptions, structure: state, n_sims: numSimulations })
      .then((res) => {
        setSimResults(res.data)
        setSimRan(true)
      })
      .catch((error) => {
        console.log(error);
      })
  }


  const reducer = (accumulator, curr) => accumulator + curr;

  // function onFileChange(e) {
  //   setSelectedFile(e.target.files[0])
  // }

  // function onFileUpload() {
  //   const formData = new FormData()

  //   formData.append(
  //     "file",
  //     selectedFile,
  //     selectedFile.name
  //   )

  //   axios
  //     .post(api_url + "apps/lm/upload_file", formData)
  //     .then((res) => {
  //       setFileData(res.data.data)
  //     })
  // }

  function SimResultTable() {
    const keys = ["Mean", "Std", "50th", "75th", "95th", "99.5th"]
    if (simRan) {
      return (
        <div>
          <div className="pt-4 grid grid-cols-7 gap-1 m-auto text-sm text-right mx-4 mb-2">
            <div><b></b></div>
            {keys.map((key) => (
              <b>{key}</b>
            ))}
            <div className="text-right"><b># Losses</b></div>
            {keys.map((key) => (
              <NumberFormat
                value={simResults.numLosses[0][key]}
                decimalScale={0}
                displayType="text"
              />
            ))}
            <div className="text-right"><b>Gross</b></div>
            {keys.map((key) => (
              <NumberFormat
                value={simResults.grossLosses[0][key]}
                decimalScale={0}
                thousandSeparator={true}
                prefix={'R '}
                displayType="text"
              />
            ))}
            <div className="text-right"><b>Recovery</b></div>
            {keys.map((key) => (
              <NumberFormat
                value={simResults.recovery[0][key]}
                decimalScale={0}
                thousandSeparator={true}
                prefix={'R '}
                displayType="text"
              />
            ))}
            <div className="text-right"><b>Reinstatement</b></div>
            {keys.map((key) => (
              <NumberFormat
                value={simResults.reinstatement[0][key]}
                decimalScale={0}
                thousandSeparator={true}
                prefix={'R '}
                displayType="text"
              />
            ))}
            <div className="text-right"><b>Net</b></div>
            {keys.map((key) => (
              <NumberFormat
                value={simResults.netLosses[0][key]}
                decimalScale={0}
                thousandSeparator={true}
                prefix={'R '}
                displayType="text"
              />
            ))}
            <div className="text-right"><b>Retention</b></div>
            {keys.map((key) => (
              <NumberFormat
                value={simResults.retention[0][key]}
                decimalScale={2}
                thousandSeparator={true}
                displayType="text"
                suffix={"%"}
              />
            ))}
          </div>
        </div>
      )
    } else {
      return null;
    }
  }



  return (
    <Page>
      <Title>Reinsurance Calculator</Title>
      <div className="text-sm">
        <p>This is a proof of concept of a reinsurance calculation app. Click and drag to reorder the individual structures or the layers within the excess of loss structures. Easily add new structures or layers with one click. Click the red bin icon to delete a structure or layer. See in real time how your changes impact your net loss.</p>
        <br />
        <p>Add or remove multiple losses by clicking the "+" and "-" respectively next to "Gross Losses". Don't want to manually add losses? Set your mean number of losses and generate severities following a <a href="https://en.wikipedia.org/wiki/Gamma_distribution">Gamma</a> distribution. The mean number of losses is limited to 100 mainly to prevent the server from dying, but also for display purposes.</p>
        <br />
        <p>If that is still not enough, why not run simulations? Scroll to the bottom and generate simulations according to your distributional assumptions. The number of simulations is limited to 10000. Note, that performance will suffer with a large number of claims and a large number of simulations because servers are expensive, and I selected the cheapest one :).</p>
        <br />
        <p className="text-red-700 font-medium">Currently this app is still in beta and the results can't be trusted. Please let me know if you find any bugs.</p>
      </div>
      <br />
      {/* <button onClick={generateLossesSwitch}>Generate Losses</button> */}
      {/* <button onClick={uploadSwitch}>Upload Losses</button> */}

      {
        enableGenerateLosses
          ? <div className="mb-3 flex flex-col">
            <div className="py-1 border rounded-t-md font-medium bg-gray-200 w-full text-center relative">
              <span>Distributional Assumptions</span>
              <button
                className="absolute right-0 w-32 mr-2 bg-white hover:bg-gray-100 text-gray-800 font-bold px-2 border border-gray-400 rounded shadow"
                onClick={generateLosses}
              >
                Generate
              </button>
            </div>
            <div className="py-1 border rounded-b-md w-full text-center grid border-t border-b grid-cols-8">
              <div className="py-1 pr-1 font-medium text-right text-sm">Poisson &lambda;:</div>
              <NumberFormat
                defaultValue={`${distributionalAssumptions.lambda_}`}
                onValueChange={(e) => updateDistributionalAssumptions("lambda_", e)}
                className="py-1 pr-1 text-right text-sm"
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return (floatValue >= 0 & floatValue <= 100);
                }}
              />
              <div className="py-1 pr-1 font-medium text-right text-sm">Gamma k:</div>
              <NumberFormat
                defaultValue={`${distributionalAssumptions.k}`}
                onValueChange={(e) => updateDistributionalAssumptions("k", e)}
                className="py-1 pr-1 text-right text-sm"
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return (floatValue > 0);
                }}
              />
              <div className="py-1 pr-1 font-medium text-right text-sm">Gamma &theta;:</div>
              <NumberFormat
                defaultValue={`${distributionalAssumptions.theta}`}
                onValueChange={(e) => updateDistributionalAssumptions("theta", e)}
                className="py-1 pr-1 text-right text-sm"
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return (floatValue > 0);
                }}
              />
              <div className="py-1 pr-1 font-medium text-right text-sm">Multiple:</div>
              <NumberFormat
                defaultValue={`${distributionalAssumptions.multiple}`}
                onValueChange={(e) => updateDistributionalAssumptions("multiple", e)}
                thousandSeparator={true}
                prefix={'R '}
                className="py-1 pr-1 text-right text-sm"
                decimalScale={0}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return (floatValue >= 0 & floatValue < 1e10);
                }}
              />
            </div>
          </div>
          : null
      }
      {/* {
        enableUpload
          ? <div className="mb-3 flex flex-col">
            <div className="py-1 border rounded-t-md font-medium bg-gray-200 w-full text-center relative">
              <span>Distributional Assumptions</span>
              <button
                className="absolute right-0 w-32 mr-2 bg-white hover:bg-gray-100 text-gray-800 font-bold px-2 border border-gray-400 rounded shadow"
                onClick={generateLosses}
              >
                Generate
              </button>
            </div>
            <div>
              <input type="file" onChange={onFileChange} />
              <button onClick={onFileUpload}>
                Upload!
              </button>
            </div>
          </div>
          : null
      } */}
      <div className="mb-3 flex flex-col">
        <div className="py-1 border rounded-t-md font-medium bg-gray-200 w-full text-center relative">
          <span>Gross Losses</span>
          <button
            className="absolute right-9 w-8 mr-2 bg-white hover:bg-gray-100 text-gray-800 font-bold px-2 border border-gray-400 rounded shadow"
            onClick={removeGrossLoss}
          >
            -
          </button>
          <button
            className="absolute right-0 w-8 mr-2 bg-white hover:bg-gray-100 text-gray-800 font-bold px-2 border border-gray-400 rounded shadow"
            onClick={addGrossLoss}
          >
            +
          </button>
        </div>
        <div className="py-1 border-l border-r w-full text-center grid grid-cols-6 px-2">
          {state.grossLosses.map((grossLoss, index) => (
            <NumberFormat
              value={grossLoss}
              onValueChange={(e) => updateGrossLoss(e, index)}
              thousandSeparator={true}
              prefix={'R '}
              className="pl-3"
              decimalScale={0}
            />
          ))}
        </div>
        <div className="py-1 border-b border-l border-r rounded-b-md font-medium bg-gray-100 w-full text-center relative ">
          {`${state.grossLosses.length} losses totalling `}
          <NumberFormat
            value={state.totalGrossLosses}
            thousandSeparator={true}
            prefix={'R '}
            className="text-center"
            displayType="text"
            decimalScale={0}
          />
        </div>
      </div>
      <div className=" flex flex-col mb-3">
        <div className="py-1 border rounded-t-md font-medium bg-gray-200 w-full text-center relative">
          <span>Reinsurance</span>
          <button
            className="absolute right-0 w-15 mr-2 bg-white hover:bg-gray-100 text-gray-800 font-bold px-2 border border-gray-400 rounded shadow"
            onClick={addXOL}
          >
            + XoL
          </button>
          <button
            className="absolute right-16 w-15 mr-2 bg-white hover:bg-gray-100 text-gray-800 font-bold px-2 border border-gray-400 rounded shadow"
            onClick={addQS}
          >
            + QS
          </button>
        </div>
        <div className="py-2 border rounded-b-md px-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="all-treaties" direction="vertical" type="treaty">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col overflow-hidden p-2"
                >
                  {
                    state.treatyOrder.map((treatyId, index) => {
                      const treaty = state.treaties[treatyId];
                      const layers = treaty.layerIds.map(layerId => state.layers[layerId])

                      return (
                        <Treaty
                          key={treaty.id}
                          treaty={treaty}
                          layers={layers}
                          index={index}
                          deleteTreaty={deleteTreaty}
                          addXolLayer={addXolLayer}
                          deleteLayer={deleteLayer}
                          updateLayer={updateLayer}
                        />
                      );
                    })
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className="mb-3 flex flex-col">
        <div className="py-1 border rounded-t-md font-medium bg-gray-200 w-full text-center relative">Net Losses</div>
        <div className="py-1 border rounded-b-md w-full grid grid-cols-6 px-2">
          {statement.netLosses.map((netLoss, index) => (
            <NumberFormat
              value={netLoss}
              thousandSeparator={true}
              prefix={'R '}
              className="pl-3"
              decimalScale={0}
            />
          ))}
        </div>
        <div className="py-1 border-b border-l border-r rounded-b-md font-medium bg-gray-100 w-full text-center relative">
          <NumberFormat
            value={statement.totalNetLosses}
            thousandSeparator={true}
            prefix={'R '}
            className="text-center"
            displayType="text"
            decimalScale={0}
          />
        </div>
      </div>
      <div className="mb-3 flex flex-col">
        <div className="py-1 border rounded-t-md font-medium bg-gray-200 w-full text-center relative">
          Run Simulation
          <button
            className="absolute right-0 w-32 mr-2 bg-white hover:bg-gray-100 text-gray-800 font-bold px-2 border border-gray-400 rounded shadow"
            onClick={runSimulation}
          >
            Run
          </button>
        </div>
        <div className="py-1 border rounded-b-md w-2 text-center border-t border-b w-full">
          <div className="w-96 grid grid-cols-2 m-auto">
            <div className="py-1 pr-1 font-medium text-right text-sm">Number of Runs:</div>
            <NumberFormat
              defaultValue={`${numSimulations}`}
              onValueChange={(e) => updateNumSimulations(e)}
              className="py-1 pr-1 text-center text-sm"
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return (floatValue <= 10000 & floatValue > 0);
              }}
            />
          </div>

          <SimResultTable />
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div className="pt-2 text-xs text-gray-400">
          <p>Version 1.0.0.beta2 - 17 Aug 2021</p>
          <ul>
            <li>* fixed a bug where changing qs cession breaks execution</li>
          </ul>
          <br />
          <br />
          <u>Version History</u>
          <p>Version 1.0.0.beta1 - 15 Aug 2021</p>
          <ul>
            <li>* added deductible</li>
            <li>* allow specify number of reinstatements</li>
            <li>* added free reinstatements</li>
            <li>* allow multiple losses</li>
            <li>* added ability to generate random losses</li>
            <li>* run multiple simulations and provide statistics</li>
          </ul>
          <br />
          <p>0.1.0.beta2 - 25 Jul 2021</p>
          <ul>
            <li>* added basic reinstatement premium</li>
          </ul>
          <br />
          <p>0.1.0.beta1 - 18 Jul 2021</p>
          <ul>
            <li>* initial version</li>
          </ul>
        </div>
        <div className="text-right pt-2 text-xs text-gray-400">

          <u>Planned improvements:</u>
          <ul>
            <li>* charting of results</li>
            <li>* upload losses from csv</li>
            <li>* save and load</li>
          </ul>
        </div>
      </div>
      <Signoff />
    </Page>
  );
}

export default Reinsurance;
