import { Title, BlockQuote, UnorderedList } from "../content/typography";
import Page from "../layout/page";
import { Signoff } from "../components/Signoff";

function Projects() {
  const riskyrouterquote =
    "A contest designed to showcase the actuarial skill set in developing novel risk engineering solutions. \
    Entrants were challenged to create an end-user application that would be actuarially grounded but address \
    a risk management problem of relevance to a typical consumer. Balona's entry, Risky Router, allows users \
    to enter start and destination points to obtain a driving route that shows just how risky that path is.  \
    This estimate flexes based on weather conditions, time of day, year of the car, whether the driver is \
    impaired and other factors.";
  const riskyrouterauthor = "https://www.casact.org/article/cas-announces-winners-15000-hacktuary-challenge";

  return (
    <Page>
      <div className="max-w-3xl mx-auto">
        <Title>
          Projects
        </Title>
        <p className="text-justify">
          A selection of some projects I have worked on over the years.
        </p>
        <br />
        <strong>Reinsurance</strong>
        <br />
        <a href="https://github.com/cbalona/reinsurance">
          https://github.com/cbalona/reinsurance
        </a>
        <br />
        <br />
        <p className="text-justify">
          Reinsurance is a python package that intends to provide a framework for reinsurance computation.
          It is built on top of NumPy and Dask. It is designed to be modular, fast and easy to use. Leveraging
          the power of Dask, it can perform parallel and distributed computation to allow for large scale
          reinsurance computation. It is still in very early development.
        </p>
        <br />
        <strong>RiskyRouter</strong>
        <br />
        <a href="https://riskyrouter.com/">
          https://riskyrouter.com/
        </a>
        <br />
        <br />
        <p className="text-justify">
          RiskyRouter.com is a website I built as an entry into the 2022 CAS Hacktuary competition.
          It was selected as a joint winner.
        </p>
        <BlockQuote quote={riskyrouterquote} author={riskyrouterauthor} />
        <br />
        <strong>Tryangle</strong>
        <br />
        <a href="https://github.com/casact/tryangle">
          https://github.com/casact/tryangle
        </a>
        <br />
        <br />
        <p className="text-justify">
          Tryangle is an automatic chainladder reserving framework. It provides scoring and optimisation
          methods based on machine learning techniques to automatically select optimal parameters to minimise
          reserve prediction error. Tryangle is built on top of the chainladder reserving package.
        </p>
        <Signoff />
      </div>
    </Page>
  );
}

export default Projects;
